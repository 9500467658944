<template>
  <card>
    <div slot="header" class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">Dane produktu</h3>
      </div>
    </div>

    <div class="pl-lg-4">
      <div class="row">
        <div class="col-lg-5">
          <base-input type="text"
                      label="EAN"
                      placeholder="EAN"
                      v-model="formData.ean"
          ></base-input>
        </div>
      </div>
      <base-input type="text"
                  label="Nazwa produktu"
                  placeholder="Nazwa"
                  v-model="formData.name"
      ></base-input>
      <div class="row">
        <div class="col-lg-6">
          <base-input label="Kategoria">
            <el-select v-model="formData.category"
                       filterable
                       placeholder="Wybierz kategorię">
              <el-option v-for="option in categoryOptions"
                         :key="option.id"
                         :label="option.name"
                         :value="option.id">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-lg-6">
          <base-input label="Linia produktowa">
            <el-select v-model="formData.line"
                       filterable
                       placeholder="Wybierz kategorię">
              <el-option v-for="option in lineOptions"
                         :key="option.id"
                         :label="option.name"
                         :value="option.id">
              </el-option>
            </el-select>
          </base-input>

        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <span class="form-control-label">Aktywny</span>
        </div>
        <div class="col-lg-3">
          <base-switch class="mr-1" v-model="formData.active" on-text="Tak" off-text="Nie" type="primary"></base-switch>
        </div>
        <div class="col-lg-3">
          <span class="form-control-label">Wewnętrzny</span>
        </div>
        <div class="col-lg-3">
          <base-switch class="mr-1" v-model="formData.internal" on-text="Tak" off-text="Nie" type="danger"></base-switch>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <span class="form-control-label">Dostępny</span>
        </div>
        <div class="col-lg-3">
          <base-switch class="mr-1" v-model="formData.available" on-text="Tak" off-text="Nie" type="primary"></base-switch>
        </div>
        <div class="col-lg-3">
          <span class="form-control-label">Archiwalny</span>
        </div>
        <div class="col-lg-3">
          <base-switch class="mr-1" v-model="formData.archived" on-text="Tak" off-text="Nie" type="danger"></base-switch>
        </div>
      </div>
    </div>

    <hr class="my-4">

    <div class="pl-lg-4">
      <div class="row">
        <div class="col-lg-6">
          <base-input label="Tagi">
            <el-select v-model="formData.tags"
                       multiple
                       filterable
                       placeholder="Wybierz tag...">
              <el-option v-for="option in tagOptions"
                         :key="option.id"
                         :label="option.name"
                         :value="option.id">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-lg-6">
          <base-input label="Produkty rekomendowane">
            <el-select v-model="formData.recommendations"
                       multiple
                       filterable
                       placeholder="Wybierz produkt...">
              <el-option v-for="option in recommendationOptions"
                         :key="option.id"
                         :label="option.name"
                         :value="option.id">
              </el-option>

            </el-select>

          </base-input>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { Select, Option } from 'element-ui'

export default {
  name: 'ProductNameForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    formData: Object,
    categoryOptions: Array,
    lineOptions: Array,
    tagOptions: Array,
    recommendationOptions: Array,
  },
};
</script>
