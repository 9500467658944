<template>
  <div class="flex flex-wrap -mx-1 md:-mx-2">
    <div
      class="flex justify-center flex-wrap w-1/3 p-1"
      v-for="({image, name}, index) in previewImages"
      :key="`prev-image-${index}`"
    >
      <div class="rounded-lg shadow-lg bg-white max-w-sm">
        <img class="rounded-t-lg" :src="image" alt=""/>
        <div class="p-3">
          <p class="text-gray-700 text-base mb-4">
            {{ name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductGalleryManageNewItemImage",
  props: {
    previewImages: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped>

</style>
