<template>
  <div>
  <div id="defaultModal" tabindex="-1" aria-hidden="true"
       class="overflow-y-auto overflow-x-hidden fixed z-50 w-full md:inset-0 h-modal md:h-full bg-black/50"
       :class="cModal ? 'fixed' : 'hidden'"
  >
    <div class="relative inset-1/2 -translate-x-1/2	 -translate-y-1/2	 p-4 w-full max-w-2xl h-full md:h-auto">
      <!-- Modal content -->
      <div class="relative  bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex justify-between items-start rounded-t border-b dark:border-gray-600">
          <gallery-modal-tabs v-model="activeComponent" />
          <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="defaultModal"
            @click="$emit('input', false)"
          >
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-2 pb-4">
          <product-gallery-manage-list @added="payload => $emit('added', payload)" class="h-100" v-if="activeComponent === 'list'" @close="$emit('input', false)"/>
          <product-gallery-manage-new-item class="h-100" v-else />
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import ProductGalleryManageList from "@/components/TailwindComponents/ProductGalleryManage/ProductGalleryManageList";
import ProductGalleryManageNewItem from "@/components/TailwindComponents/ProductGalleryManage/ProductGalleryManageNewItem";
import GalleryModalTabs from "@/components/TailwindComponents/ProductGalleryManage/atoms/GalleryModalTabs";
export default {
  name: "ProductGalleryManage",
  components: {
    GalleryModalTabs,
    ProductGalleryManageList,
    ProductGalleryManageNewItem
  },
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      activeComponent: 'list',
    }
  },
  computed: {
    cModal: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    },
  },
  methods: {
    handleActiveComponent(active) {
      if ( this.activeComponent !== active ) {
        this.activeComponent = active
      }
    }
  }
}

</script>

<style scoped>

</style>
