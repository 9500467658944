<template>
  <li class="mr-2">
    <a href="#" class="inline-block p-4 rounded-t-lg" :class="[classList]" @click.prevent="handleClick">
      <slot></slot>
    </a>
  </li>
</template>

<script>
export default {
  name: "GalleryModalTabItem",
  props: {
    value: {
      type: String,
      default: ''
    },
    component: {
      type: String,
      default: ''
    }
  },
  computed: {
    classList() {
      return this.value === this.component ? 'text-blue-600 bg-gray-100 ': 'hover:text-gray-600 hover:bg-gray-50'
    }
  },
  methods: {
    handleClick() {
      this.$emit('input', this.component )
    }
  }
}
</script>

<style scoped>

</style>
