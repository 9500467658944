<template>
  <div class="atom-gallery-list-item flex flex-wrap w-1/3 p-1">
    <component :is="tag" class="w-full p-1 md:p-2 cursor-pointer" :class="customClass">
      <slot></slot>
      <img
        alt="gallery"
        :class="[`block object-cover object-center w-full rounded-lg`, imgClassList]"
        :src="src"
      />
      <slot name="details"></slot>
    </component>
  </div>
</template>

<script>
export default {
  name: "GalleryItemTemplate",
  props: {
    isInput: {
      type: Boolean,
      default: () => false
    },
    src: {
      type: String,
      required: true
    },
    customClass: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    imgClassList() {
      return this.isInput ? 'h-full peer-checked:outline peer-checked:outline-2 peer-checked:outline-offset-2' : ''
    },
    tag() {
      return this.isInput ? 'label' : 'div'
    }
  }
}
</script>

<style scoped>

</style>
