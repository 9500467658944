<template>
  <div class=" text-gray-700 ">
    <div class="container px-1 py-2 mx-auto lg:pt-12 lg:px-32">
      <div class="overflow-auto max-h-[300px] mb-4">
        <div class="flex flex-wrap -m-1 md:-m-2">
          <gallery-list-item
            v-model="selected"
            v-for="(image, index) in cImages"
            :key="`gallery-item-${image}-${index}`"
            :input-value="image"
          />
        </div>
      </div>
      <button type="submit" class="bg-gray-700 p-2.5 text-sm text-white rounded" @click="handleClick">Dodaj wybrane</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GalleryListItem from "@/components/TailwindComponents/ProductGalleryManage/atoms/GalleryListItem";

export default {
  name: "ProductGalleryManageList",
  components: {
    GalleryListItem
  },
  computed: {
    ...mapState('ProductGalleryAddItem', ['items']),
    cImages() {
      return this.items.map( image => {
        const url = image.url.split('https://cdn.clochee.com/prod/').join('')
        return 'https://cdn.clochee.com/prod/' + url
      })
    }
  },
  data() {
    return {
      images: [
        'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp',
        'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp',
        'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp',
        'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(70).webp',
        'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(76).webp',
        'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp',
        'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(74).webp',
        'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(75).webp',
        'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(70).webp',
        'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(76).webp',
        'https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(72).webp'
      ],
      selected: []
    }
  },
  methods: {
    async getImages() {

    },
    handleClick(){
      const payload = [ ...this.selected ]
      this.selected = []
      this.$emit('added', payload);
      this.$emit('close');
    }
  },
  async beforeMount() {
    await this.$store.dispatch('ProductGalleryAddItem/getMediaItems')
  }
}
</script>

<style scoped>

</style>
