<template>
  <card>
    <div slot="header" class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">Kwoty</h3>
      </div>
    </div>

    <div class="pl-lg-4">
      <div class="row">
        <div class="col-lg-6">
          <base-input type="text"
                      label="Cena bazowa netto"
                      placeholder="Netto"
                      v-model="formData.baseNetPrice"
          >
            <small slot="append" class="font-weight-bold">zł</small>
          </base-input>
        </div>
        <div class="col-lg-6">
          <base-input type="text"
                      label="Cena bazowa brutto"
                      placeholder="Brutto"
                      v-model="formData.baseGrossPrice"
          >
            <small slot="append" class="font-weight-bold">zł</small>
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <base-input type="text"
                      label="Stały rabat"
                      placeholder="Rabat"
                      v-model="formData.fixedDiscount"
          >
            <small slot="append" class="font-weight-bold">%</small>
          </base-input>
        </div>
        <div class="col-lg-6">
          <base-input type="text"
                      label="Stawka VAT"
                      placeholder="VAT"
                      v-model="formData.taxRate"
          >
            <small slot="append" class="font-weight-bold">%</small>
          </base-input>
        </div>
      </div>
    </div>

  </card>
</template>

<script>
export default {
  name: 'ProductPriceForm',
  props: {
    formData: Object,
  },
};
</script>
