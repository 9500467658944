<template>
  <div class="c-product-gallery-new-item">
    <form action="" @submit.prevent="submitNewItems" >
      <div class="block">
        <div class="container px-5 py-2 mx-auto lg:pt-12 lg:px-32">
          <ProductGalleryManageNewItemImage
            :preview-images="previewImages"
          />
        </div>
        <ProductGalleryManageNewItemFileDrop @change="uploadFiles"/>
      </div>
      <button type="submit" class="bg-gray-700 p-2.5 text-sm text-white rounded">Wyślij zdjęcia</button>
    </form>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

import ProductGalleryManageNewItemImage
  from "@/components/TailwindComponents/ProductGalleryManage/ProductGalleryManageNewItem/atoms/ProductGalleryManageNewItemImage";
import ProductGalleryManageNewItemFileDrop
  from "@/components/TailwindComponents/ProductGalleryManage/ProductGalleryManageNewItem/atoms/ProductGalleryManageNewItemFileDrop";
export default {
  name: "ProductGalleryManageNewItem",
  components: {ProductGalleryManageNewItemFileDrop, ProductGalleryManageNewItemImage },
  data() {
    return {
      Images: null,
      url: ''
    }
  },
  computed: {
    ...mapState('ProductGalleryAddItem', ['itemsToAdd']),
    previewImages() {
      return [...this.itemsToAdd ?? [] ].map( image => ({
        image: URL.createObjectURL(image),
        name: image.name
      }))
    }
  },
  methods: {
    ...mapMutations('ProductGalleryAddItem', ['setItemsToAdd']),
    ...mapActions('ProductGalleryAddItem', ['submitNewItems']),
    uploadFiles(files) {
      this.setItemsToAdd(files)
    }
  }
}
</script>

<style scoped>

</style>
