import { render, staticRenderFns } from "./ProductGalleryManageList.vue?vue&type=template&id=583dbf8b&scoped=true&"
import script from "./ProductGalleryManageList.vue?vue&type=script&lang=js&"
export * from "./ProductGalleryManageList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583dbf8b",
  null
  
)

export default component.exports