<template>
  <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
    <gallery-modal-tab-item component="list" v-model="selected">
      Wybierz zdjęcia z galerii
    </gallery-modal-tab-item>
    <gallery-modal-tab-item component="new-item" v-model="selected">
      Dodaj nowe zdjęcie
    </gallery-modal-tab-item>
  </ul>
</template>

<script>
import GalleryModalTabItem from "@/components/TailwindComponents/ProductGalleryManage/atoms/GalleryModalTabItem";
export default {
  name: "GalleryModalTabs",
  components: {GalleryModalTabItem},
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(newVal) {
        return this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style scoped>

</style>
