<template>
  <card>
    <div slot="header" class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">Zdjęcia produktu</h3>
      </div>
    </div>
    <div class="component-gallery-manage">
      <div class="container px-1 py-2 mx-auto lg:pt-12 lg:px-32">
        <div>
          <draggable v-model="items" v-bind="dragOptions"
                     class="draggable"
                     @start="drag = true"
                     @end="drag = false">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <product-photo-item
                v-for="element in items"
                :key="element.order"
                :src="element.url"
                @delete="deleteImage"
              />
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    <div class="btn btn-primary btn-block" @click="modalActive = true">Dodaj więcej zdjęć</div>
    <product-gallery-manage @added="payload => $emit('added', payload)" v-model="modalActive" />
  </card>
</template>
<script>
import draggable from 'vuedraggable'

import ProductPhotoItem from "@/components/TailwindComponents/ProductPhotosManage/atoms/ProductPhotoItem";
import ProductGalleryManage from "@/components/TailwindComponents/ProductGalleryManage";
export default {
  name: 'ProductPhotosManage',
  components: {
    ProductGalleryManage,
    draggable,
    ProductPhotoItem
  },
  data() {
    return {
      drag: false,
      modalActive: false
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    items: {
      get() {
        return this.value
      },
      set(items) {
        console.log('photoItems', items.map( ( { media, thumbUrl, url }, index ) => ({
          media,
          thumbUrl,
          url,
          order: index
        })))
        this.$emit('input', items)
      }
    }
  },
  methods: {
    deleteImage(src) {
      this.items = this.items.filter( image => image.url !== src )
    }
  },
}
</script>

<style lang="scss">
.draggable {
  & > span {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
