<template>
  <div class="atom-gallery-list-item flex flex-wrap w-1/3 p-1 group">
    <div class="w-full p-1 md:p-2 cursor-pointer relative">
      <div
        :class="`
          absolute
          right-0 top-0
          hidden group-hover:inline-block
          drop-shadow-md
          rounded-full
          bg-sky-500/100
          p-1
          w-6 h-6
          text-xs text-white text-center
        `"
        @click="handleDelete"
      >X</div>
      <img
        alt="gallery"
        :class="[`block object-cover object-center w-full rounded-lg`]"
        :src="src"
        height="150"
        width="150"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductPhotoItem",
  props: {
    src: {
      type: String,
      required: true
    }
  },
  methods: {
    handleDelete() {
      this.$emit('delete', this.src)
    }
  }
}
</script>

<style scoped>

</style>
