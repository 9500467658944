<template>
  <gallery-item-template :src="inputValue" :is-input="true">
    <input type="checkbox" :value="inputValue" v-model="model" class="peer hidden">
  </gallery-item-template>
</template>

<script>
import GalleryItemTemplate from "@/components/TailwindComponents/ProductGalleryManage/atoms/GalleryItemTemplate";

export default {
  name: "GalleryListItem",
  components: {
    GalleryItemTemplate
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    inputValue: {
      type: String,
      required: true
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
